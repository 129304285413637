import i18n from '@/i18n'
import Validator from '@/validators'
var newPasscodeValue = ''
const newPwdValidator = (rule, value, callback) => {
  newPasscodeValue = value
  callback()
}
const confirmPwdValidator = (rule, value, callback) => {
  if (value !== newPasscodeValue) {
    return callback(new Error(i18n.global.t('general.code-not-match')))
  } else {
    callback()
  }
}

export const baseFormConfig = {
  formItems: [
    {
      field: 'passCode',
      type: 'password',
      label: 'profile.old-password',
      otherOptions: {
        maxlength: 64
      }
    },
    {
      field: 'newPassCode',
      type: 'password',
      label: 'profile.new-password',
      otherOptions: {
        maxlength: 64
      }
    },
    {
      field: 'confirmPassCode',
      type: 'password',
      label: 'profile.confirm-password',
      otherOptions: {
        maxlength: 64
      }
    }
  ],
  validateRules: {
    passCode: [
      { required: true, message: 'general.required', trigger: 'change' }
    ],
    newPassCode: [
      { required: true, message: 'general.required', trigger: 'change' },
      {
        validator: Validator.regex,
        regex:
          /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[:;?.,<>()^`![|~\\@#$%&*~])).{8,20}$/,
        message: 'validator.new-password-validate'
      },
      { validator: newPwdValidator, trigger: 'change' }
    ],
    confirmPassCode: [
      { required: true, message: 'general.required', trigger: 'change' },
      { validator: confirmPwdValidator, trigger: 'change' }
    ]
  }
}
