<template>
  <div>
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      @handleNewClick="handleSubmitClick"
      :pageTitle="$t('general.router-change-password')"
    >
      <!-- <template #alertContent>
        <el-alert
          class="alert-tip"
          :title="alertMessage"
          type="warning"
          show-icon
        />
      </template> -->
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/profile.resetpsw.js'
// import { useStore } from 'vuex'
import { ref, getCurrentInstance } from 'vue'
// const store = useStore()
const baseFormRef = ref('')
// const alertMessage = ref('')

const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
const handleGlobalLogout = appContext.config.globalProperties.$handleLogout

// store.dispatch('user/getPasswordExpiry').then((res) => {
//   alertMessage.value = res.data.resultMessage
// })
const handleSubmitClick = async (data) => {
  handleMessageCommit('user/changePassword', data).then(() => {
    setTimeout(() => {
      handleGlobalLogout()
    }, 3000)
  })
}
</script>
